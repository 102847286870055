import { UpdateParams } from "react-admin";
import { Team } from "../../model/team/Team";
import { DocumentData, DocumentSnapshot } from "../../services/persistence";
import { EntityMapper } from "../core/EntityMapper";

export class TeamsMapper extends EntityMapper<Team> {
    async toFirestore(params: UpdateParams): Promise<DocumentData> {
        const { data } = params
        
        return Object.assign(
            {},
            super.toFirestore(params),
            this.toFirestoreField(data, 'name'),
            this.toFirestoreField(data, "myKMHubLink"),
            this.toFirestoreField(data, 'type'),
            this.toFirestoreField(data, 'details'),
            this.toFirestoreField(data, 'description')
        ) as DocumentData;
    }

    fromFirestore(snapshot: DocumentSnapshot): Team {
        const data = snapshot.data();
        const collection = snapshot.ref.parent.id;

        let type;
        switch (collection) {
            case "departmentsGovernance": 
                type = "governance";
                break;
            case "departmentsPractice":
                type = "practice";
                break;
            case "departmentsManagement":
                type = "management";
                break;
        }

        return Object.assign(
            new Team(),
            super.fromFirestore(snapshot),
            this.fromFirestoreField(data, 'name'),
            this.fromFirestoreField(data, "myKMHubLink"),
            this.fromFirestoreField(data, 'details'),
            this.fromFirestoreField(data, 'description'),
            (type !== undefined ? { "type": type } : {})
        );
    }
}
