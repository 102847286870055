import { isBefore } from "date-fns";
import {
    BooleanInput,
    Edit,
    EditProps,
    ImageField,
    ImageInput,
    Loading,
    SaveButton,
    SimpleForm,
    Toolbar,
    TopToolbar,
    useDataProvider,
} from "react-admin";
import { PageTitle } from "../../../../components/pageTitle";
import i18n from "../../../../language/i18n";
import MenuFormGrid from "./menuFormGrid";

import styles from "./index.module.css";
import MusicFormGrid from "./musicFormGrid";
import { useCallback, useEffect, useState } from "react";
import AppsFormGrid from "./appsFormGrid";
import KnowledgeFormGrid from "./knowledgeFormGrid";
import KioskFormGrid from "./kioskFormGrid";

interface homepageProps {
    id: string;
    creationDate: Date;
    updatedDate: Date;
    menu: string;
    startDate: Date;
    endDate: Date;
    songName: string;
    songArtist: string;
    songSuggestedBy: string;
    songLink: string;
    background: any;
}

const validateHomepageSetup = async (values: homepageProps) => {
    const errors: any = {};


    if (!values.menu) {
        errors.menu = i18n.t("homepage.setup.errors.menu");
    }

    if (values.startDate && values.endDate) {
        if (isBefore(new Date(values.endDate), new Date(values.startDate))) {
            errors.startDate = i18n.t("homepage.setup.errors.invalidDates");
        }
    }

    if (!values.songName) {
        errors.songName = i18n.t("homepage.setup.errors.songName");
    }

    if (!values.songArtist) {
        errors.songArtist = i18n.t("homepage.setup.errors.songArtist");
    }

    if (!values.songSuggestedBy) {
        errors.songSuggestedBy = i18n.t("homepage.setup.errors.songSuggestedBy");
    }

    let image;
    try {

        image = await loadImage(values.background?.src)
    } catch (error) {
        console.error(error)
    }

    if (!values.background) {
        errors.background = i18n.t("homepage.setup.errors.background")
    } else if ((image?.naturalWidth && image.naturalWidth !== 3555) || (image?.naturalHeight && image.naturalHeight !== 2370)) {
        errors.background = i18n.t("homepage.setup.errors.backgroundImageSize")
    }

    return errors;
};

const HomepageSetupToolbar = (props: EditProps) => (
    <Toolbar {...(props as any)}>
        <SaveButton redirect={false} />
    </Toolbar>
);

const HomepageSetupActions = () => {
    return (
        <TopToolbar className={styles.toolbar}>
            <PageTitle> {i18n.t("sidebar.homepage")} </PageTitle>
        </TopToolbar>
    );
};

export const SetupHomepage = (props: EditProps) => {
    const [loading, setLoading] = useState(true);
    const [apps, setApps] = useState<object[] | undefined>(undefined);
    const [kioskItems, setKioskItems] = useState<object[] | undefined>(undefined)
    const dataProvider = useDataProvider();

    const fetchData = useCallback(async () => {
        try {
            const appsRes = await dataProvider.getList('application', { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any)
            const appsList = appsRes.data.map((p: any) => { return Object.create({ id: p.id, name: p.title }) });
            const kioskItemsRes = await dataProvider.getList("kiosk_item", { filter: { searchText: "" }, pagination: { perPage: 1000, page: 1 } } as any)
            const kioskItemsList = kioskItemsRes.data.map((p: any) => { return Object.create({ id: p.id, name: p.name }) })
            setKioskItems(kioskItemsList)
            setApps(appsList);
            setLoading(false);
        } catch (e) {
            setApps(undefined);
            setLoading(false);
        }
    }, [dataProvider]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading === true) {
        return <Loading />
    };

    return (
        <Edit mutationMode="pessimistic" {...props} actions={<HomepageSetupActions />} className={"mid-container"}>
            <SimpleForm
                validate={validateHomepageSetup}
                toolbar={<HomepageSetupToolbar {...props} />}
            >
                <ImageInput
                    source="background"
                    accept="image/*"
                    multiple={false}
                    label={i18n.t("homepage.background")}
                    labelMultiple=""
                >
                    <ImageField source="src" />
                </ImageInput>
                <BooleanInput source={"autoRotate"} label={i18n.t("homepage.autoRotate")} style={{width: "100%"}} />
                <MenuFormGrid />
                <MusicFormGrid />
                <AppsFormGrid apps={apps} />
                <KioskFormGrid kioskItems={kioskItems} />
                <KnowledgeFormGrid />
            </SimpleForm>
        </Edit>
    );
};

function loadImage(path: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = path;
        img.onload = () => {
            resolve(img);
        };
        img.onerror = e => {
            reject(e);
        };
    });
}