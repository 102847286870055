import { Entity } from "../core/Entity";

export class Homepage extends Entity {
    public menu?: string;
    public startDate?: Date;
    public endDate?: Date;
    public songName?: string;
    public songArtist?: string;
    public songSuggestedBy?: string;
    public knowledgeSection?: Array<object>;
}
